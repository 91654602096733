import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { css } from '@/ui/styles'

const { devices, fontSizes, fontWeight, gaps, lineHeights } = css

type FooterProps = {
  isAuth?: boolean
  isSidebar?: boolean
  isLanding?: boolean
}

let isAuth = false
let isSidebar = false
let isLanding = false

const setIsAuth = (flag = false) => {
  isAuth = flag
  return isAuth
}

const setIsSidebar = (flag = false) => {
  isSidebar = flag
  return isSidebar
}

const setIsLanding = (flag = false) => {
  isLanding = flag
  return isLanding
}

export const Footer = styled.footer<FooterProps>`
  width: ${({ isAuth }) => setIsAuth(isAuth) && '100%'};

  border-top: ${({ theme, isLanding }) => !isLanding && `1px solid ${theme.colors.dark10}`};
  padding: 0;
  margin: 0;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: ${({ isSidebar }) => (setIsSidebar(isSidebar) ? '28rem' : '16rem')};
  font-size: ${({ isLanding }) => (setIsLanding(isLanding) ? fontSizes.fs18 : fontSizes.fs14)};
  font-weight: ${fontWeight.fw500};
  line-height: ${lineHeights.lh20};

  @media ${devices.d768} {
    margin-top: 20rem;
    margin-left: 8rem;
    margin-right: 8rem;
    font-size: ${fontSizes.fs14};
  }
`

export const FooterInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${gaps.g24};
  margin-bottom: ${isSidebar ? gaps.g12 : gaps.g20};

  @media ${devices.d768} {
    margin-bottom: ${gaps.g8};
  }
`

export const FooterInfo = styled.p``

export const FooterCopyrightWrapper = styled.div`
  display: ${() => (isAuth || isSidebar ? 'block' : 'flex')};
  justify-content: space-between;

  @media ${devices.d768} {
    display: block;
  }
`

export const FooterCopyrightLinkWrapper = styled.div`
  width: ${() => (isAuth ? '80%' : '100%')};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${isSidebar ? gaps.g12 : gaps.g20};
  row-gap: ${gaps.g8};

  @media ${devices.d768} {
    margin-bottom: ${gaps.g8};
    width: 100%;
  }
`

// TODO: пересмотреть возможности UiLink
export const FooterLink = styled(Link)``

export const FooterLinkAbsolute = styled.a`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${fontWeight.fw700};
  margin-right: ${gaps.g12};
  white-space: nowrap;

  &,
  &:visited {
    color: ${({ theme }) => theme.colors.accent};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.accentHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.accentPressed};
  }

  &.privacy_policy_link {
    text-wrap: auto;
  }
`

export const FooterCopyright = styled.p`
  white-space: nowrap;
`

export const FooterCopyrightText = styled.p`
  white-space: nowrap;
  margin-bottom: ${gaps.g8};
`
