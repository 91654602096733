import { Icons } from '@/ui/components/UiIcon/types'

import { SocialPartner } from '@/types/requestDataTypes'
import { EnvBooleanValue } from '@/enums/auth'
import { RoutePath } from '@/router/enums'

import defaultServiceLogo from '@/assets/img/default-service-logo.svg'

export const DEFAULT_TIMER_VALUE = 60

export const LOGIN_HISTORY_PAGINATION = 10

export const IS_AUTO_TESTER = String(import.meta.env.VITE_APP_IS_AUTO_TESTER).includes(EnvBooleanValue.True)
export const IS_LOCAL = String(import.meta.env.VITE_APP_IS_LOCAL).includes(EnvBooleanValue.True)
export const IS_PROD = String(import.meta.env.VITE_APP_IS_PROD).includes(EnvBooleanValue.True)
export const IS_REAL_INTEGRATION =
  String(import.meta.env.VITE_APP_IS_REAL_INTEGRATION).includes(EnvBooleanValue.True) || IS_PROD

export const CAPTCHA_EXECUTING_DELAY_MS = 1000

export const CLOSE_TOASTIFY_MS = 2000

export const ACCEPT_COOKIE_LOCALSTORAGE_KEY = 'acceptCookie'
export const ACCEPT_COOKIE_FONT_TIMEOUT_MS = 6000

export const LOADER_SIZE = {
  XS: 8,
  S: 12,
  M: 18,
  L: 36
}

export const LOADER_GAP = {
  XS: 6,
  S: 8,
  M: 10,
  L: 20
}

export const DEVICE_TRANSITION_POINT = 1024

export const TEST_NUMBER_CODE = '+800'

export const TEST_NUMBER_MASK = '+### #### ####'

export const TEST_NUMBER_LENGTH = 12

export const NAME_PATTERN = /^[А-яЁёA-z\s-]+$/

export const NICKNAME_PATTERN = /^[а-яА-Яa-zA-Z0-9+-_!?Ёё@%.:()^№& ]+$/

export const CITY_PATTERN = /^[А-яЁёA-z0-9\s-]+$/

// https://regex101.com/r/drdBrF
export const EMAIL_PATTERN = /^[a-z0-9]+(?:[.\-_'+][a-z0-9]+)*@([a-z0-9]+(?:[-][a-z0-9]+)*\.)+[a-z]{2,}$/i

export const FALLBACK_IMAGE = defaultServiceLogo

export const RELEASE_DATE_YEAR = 2022

export const MAIN_FONT_NAME = 'PT Root UI'

export const IGNORE_OIDC_ERROR_PAGES: string[] = [
  `/${RoutePath.Auth}/${RoutePath.Yandex}`,
  `/${RoutePath.Auth}/${RoutePath.Vk}`,
  `/${RoutePath.Auth}/${RoutePath.Blocked}`
]

export const SOCIAL_ICON_MAP: Record<SocialPartner, Icons> = {
  yandex_id: 'yandexLogo',
  vk_id: 'vkLogo'
}
