import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { ThemeList, useThemeUpdater } from '@/ui/contexts/themeContext'

import useClientData from '@/api/requests/apiClientData'
import { useBrandBackground } from '@/hooks/useBrendBackground'
import { useCustomColors } from '@/hooks/useCustomColors'
import { usePlaywrightTestStatus } from '@/hooks/usePlaywrightTestStatus'

import { RoutePath } from '@/router/enums'

import { useClientInfoSelector } from './clientInfoStore'

type PropsType = {
  children: React.ReactNode
}

export const ClientInfoStoreController = ({ children }: PropsType) => {
  const status = useClientInfoSelector.use.status()
  const clientInfo = useClientInfoSelector.use.clientInfo()
  const setStatus = useClientInfoSelector.use.setStatus()

  const { setTheme } = useThemeUpdater()
  const { updateCustomColors } = useCustomColors()
  const { setBrandBackground } = useBrandBackground()

  const { isFetching } = useClientData()

  React.useEffect(() => {
    if (clientInfo) {
      setTheme(clientInfo.theme || ThemeList.DEFAULT)
      updateCustomColors(clientInfo.custom_colors)
      setBrandBackground(clientInfo)
    } else if (isFetching) {
      setStatus('loading')
    }
  }, [clientInfo, isFetching, setBrandBackground, setStatus, setTheme, status, updateCustomColors])

  return children
}

export const useClientInfo = () => {
  const { pathname } = useLocation()
  const isPlayWrightTest = usePlaywrightTestStatus()
  // TODO пока нет отдельной ручки с флагом kfp, в попапе используется clientInfo без login_challenge
  const isYandexIDAuthHandlerPage = location.pathname.includes(`/${RoutePath.Auth}/${RoutePath.Yandex}`)
  const isQrPage = location.pathname.includes(`/${RoutePath.Auth}/${RoutePath.Qr}`)
  const isAuthInit = pathname.slice(1) === RoutePath.Auth || isYandexIDAuthHandlerPage || isQrPage || isPlayWrightTest
  const clientInfo = useClientInfoSelector.use.clientInfo()
  const status = useClientInfoSelector.use.status()
  const setStatus = useClientInfoSelector.use.setStatus()

  React.useEffect(() => {
    if (!clientInfo && status !== 'error' && isAuthInit) {
      setStatus('needInit')
    }
  }, [clientInfo, setStatus, status, isAuthInit])

  return clientInfo
}
