import * as React from 'react'
import { Outlet } from 'react-router-dom'

import UiLoader from '@/ui/components/UiLoader'
import { ThemeList, useThemeUpdater } from '@/ui/contexts/themeContext'
import Footer from '@/components/Footer'

import useCurrentRouteName from '@/hooks/useCurrentRouteName'

import { RoutePath } from '@/router/enums'

import * as S from './LandingLayout.style'

const LandingLayout = () => {
  const pageName = useCurrentRouteName()
  const { setTheme } = useThemeUpdater()

  React.useEffect(() => {
    setTheme(ThemeList.DEFAULT)
  }, [setTheme])

  const fallback = (
    <S.FlexBoxCentered>
      <UiLoader />
    </S.FlexBoxCentered>
  )

  return (
    <S.LandingLayout data-pagename={pageName}>
      <S.Content pageName={pageName}>
        <React.Suspense fallback={fallback}>
          <Outlet />
        </React.Suspense>
      </S.Content>
      <div>
        <S.Separator />
        <S.FooterWrapper>
          <Footer isLanding isAbout={pageName === RoutePath.About} isProducts={pageName === RoutePath.Products} />
        </S.FooterWrapper>
      </div>
    </S.LandingLayout>
  )
}

export default LandingLayout
